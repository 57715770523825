<script setup lang="ts">
useLocaleHead({ // https://v8.i18n.nuxtjs.org/guide/seo#setup
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})
</script>

<template>
  <main class="px-10 py-20 text-center">
    <slot />
    <Footer />
    <div class="mx-auto mt-5 text-center text-sm opacity-25">
      [Home Layout]
    </div>
  </main>
</template>
